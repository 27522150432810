import Button from '@/components/Button'
import Dialog from '@/components/Dialog'
import FacebookLogin from '@/components/FacebookLogin'
import { useAuthPrompt } from '@/context/authPrompt'
import { toLoginAuthUrl, toSignUpAuthUrl } from '@/lib/authUrl'
import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'

interface Props {
  persistent?: boolean
}

export default function AuthDialog(props: Props): JSX.Element {
  const { t } = useTranslation('common')
  const { toggleAuthPrompt } = useAuthPrompt()

  const router = useRouter()
  const loginAuthUrl = toLoginAuthUrl(router.asPath)
  const signUpAuthUrl = toSignUpAuthUrl(router.asPath)

  return (
    <Dialog customClass="bg-[#f9f5ef] p-0" onClose={!props.persistent ? () => toggleAuthPrompt() : undefined}>
      <div className="text-center p-8 text-[#19171d] flex flex-col gap-4">
        <h1 className="text-2xl font-bold">{t('authDialog.title')} </h1>
        <div className="flex justify-center">
          <span className="text-base max-w-[480px]">{t('authDialog.subtitle')}</span>
        </div>
        <div className="text-base">{t('authDialog.hint')}</div>
        <div className="flex justify-center gap-4 flex-col md:flex-row">
          <Button
            className="maxh-48 fs-xs"
            theme="primary"
            component="a"
            label={t('authDialog.buttons.labels.signIn')}
            href={loginAuthUrl}
          />
          <Button
            className="maxh-48 fs-xs"
            theme="secondary"
            component="a"
            label={t('authDialog.buttons.labels.signUp')}
            href={signUpAuthUrl}
          />
        </div>
        <div className="text-base">{t('authDialog.orText')}</div>
        <FacebookLogin />
      </div>
    </Dialog>
  )
}
