import { ReactNode } from 'react';
interface DialogProps {
  customClass?: string;
  children?: ReactNode;
  heading?: String;
  onClose?: any;
  fullScreen?: boolean;
}
export default function Dialog({
  customClass,
  children,
  heading,
  onClose,
  fullScreen
}: DialogProps): JSX.Element {
  return <div className={modalContainer}>
      <div className={`${fullScreen ? modalContentFullScreen : modalContent} ${customClass ? customClass : ''}`} sx={{
      variant: 'styles.dialog.container'
    }}>
        <div className={modalCloseContainer}>
          {onClose ? <button onClick={onClose} className={modalCloseButton}>
              <img src="/icons/close.svg" width="24px" />
            </button> : null}
        </div>
        {heading && <h2>{heading}</h2>}
        {children}
      </div>
    </div>;
}
const modalContainer = "m1raj2fv";
const modalContent = "m862wb7";
const modalContentFullScreen = "mfaswes";
const modalCloseContainer = "mygdjpa";
const modalCloseButton = "m13sy7m0";

require("./index.linaria.module.css!=!../../../node_modules/@linaria/webpack-loader/node_modules/@linaria/webpack5-loader/lib/outputCssLoader.js?cacheProvider=!./index.tsx");